
import React from 'react'
import { useAuth, useLoginWithRedirect } from '@frontegg/react'
import { Workflow } from 'lucide-react'

const Login: React.FC = () => {
  const { isAuthenticated } = useAuth()
  const loginWithRedirect = useLoginWithRedirect()

  if (isAuthenticated) {
    return null
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white/5 backdrop-blur-lg rounded-2xl p-8 shadow-2xl">
        <div className="flex flex-col items-center space-y-6">
          <img 
            src="https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/6656d22b3e8622dd354bb35c_Logo.svg" 
            alt="FixFinder Logo" 
            className="w-[244px] h-[53px]"
          />
          
          <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">
            Workflow Orchestrator
          </h1>
          
          <p className="text-gray-300 text-center max-w-sm">
            Streamline your automation workflow with our powerful orchestration platform
          </p>

          <button
            onClick={() => loginWithRedirect()}
            className="w-full flex items-center justify-center bg-gradient-to-r from-blue-500 to-emerald-500 text-white text-sm font-semibold rounded-lg py-3 px-4 hover:from-blue-600 hover:to-emerald-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300 group"
          >
            <Workflow className="w-4 h-4 mr-2 group-hover:rotate-12 transition-transform" />
            Sign In with SSO
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login
