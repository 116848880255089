
import React, { useState } from 'react';
import IntegrationConfig from './IntegrationConfig';

const Integrations: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'all' | 'connected'>('all');
  const [searchTerm, setSearchTerm] = useState('');
const [selectedAppName, setSelectedAppName] = useState('');
const [integrationConfigOpen, setIntegrationConfigOpen] = useState(false);

  const apps = [
    { id: 'airslate', name: 'AirSlate', icon: '📝', description: 'Document workflow automation' },
    { id: 'atera', name: 'Atera', icon: '🛠️', description: 'Remote monitoring and management' },
    { id: 'automate', name: 'Automate.io', icon: '⚙️', description: 'Business process automation' },
    { id: 'automox', name: 'Automox', icon: '🔧', description: 'Patch management platform' },
    { id: 'auvik', name: 'Auvik', icon: '📡', description: 'Network management' },
    { id: 'bitdefender', name: 'Bitdefender', icon: '🛡️', description: 'Security solutions' },
    { id: 'bloomfire', name: 'Bloomfire', icon: '🔥', description: 'Knowledge sharing platform' },
    { id: 'bms', name: 'BMS', icon: '💻', description: 'Business management system' },
    { id: 'box', name: 'Box', icon: '📦', description: 'Cloud content management' },
    { id: 'chef', name: 'Chef', icon: '👨‍🍳', description: 'Infrastructure automation' },
    { id: 'coda', name: 'Coda', icon: '📓', description: 'Document collaboration' },
    { id: 'confluence', name: 'Confluence', icon: '🌐', description: 'Team collaboration' },
    { id: 'cwmanage', name: 'CW Manage', icon: '🎛️', description: 'Business management platform' },
    { id: 'crowdstrike', name: 'CrowdStrike', icon: '🦅', description: 'Endpoint security' },
    { id: 'dattormm', name: 'Datto RMM', icon: '🔍', description: 'Remote monitoring' },
    { id: 'document360', name: 'Document360', icon: '📚', description: 'Documentation platform' },
    { id: 'dropbox', name: 'DropBox', icon: '📥', description: 'File hosting service' },
    { id: 'egnyte', name: 'Egnyte', icon: '🗄️', description: 'Content services platform' },
    { id: 'freshdesk', name: 'Freshdesk', icon: '🎯', description: 'Customer support' },
    { id: 'freshservice', name: 'FreshService', icon: '🔨', description: 'IT service management' },
    { id: 'graphus', name: 'Graphus', icon: '📊', description: 'Email security' },
    { id: 'guru', name: 'Guru', icon: '🧠', description: 'Knowledge management' },
    { id: 'halopsa', name: 'HaloPSA', icon: '🌟', description: 'Professional services automation' },
    { id: 'helpscout', name: 'Help Scout', icon: '💡', description: 'Customer service platform' },
    { id: 'itglue', name: 'IT Glue', icon: '📘', description: 'IT documentation' },
    { id: 'intune', name: 'Intune', icon: '📱', description: 'Device management' },
    { id: 'jira', name: 'Jira', icon: '🎫', description: 'Project management' },
    { id: 'jumpcloud', name: 'JumpCloud', icon: '☁️', description: 'Directory platform' },
    { id: 'kaseya', name: 'Kaseya VSA', icon: '🎮', description: 'IT management' },
    { id: 'logicmonitor', name: 'LogicMonitor', icon: '📈', description: 'Infrastructure monitoring' }
  ];

  const filteredApps = apps.filter(app =>
    app.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Integrations</h1>
      
      <div className="bg-white rounded-lg shadow">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            <button
              onClick={() => setActiveTab('all')}
              className={`py-4 px-6 ${
                activeTab === 'all'
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Available Integrations
            </button>
            <button
              onClick={() => setActiveTab('connected')}
              className={`py-4 px-6 ${
                activeTab === 'connected'
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Connected Apps
            </button>
          </nav>
        </div>
        
        <div className="p-6">
          {activeTab === 'all' ? (
            <>
              <div className="mb-6">
                <input
                  type="text"
                  placeholder="Search integrations..."
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {filteredApps.map((app) => (
                  <div key={app.id} className="border rounded-lg p-4 hover:shadow-lg transition-shadow">
                    <div className="flex items-center gap-3 mb-2">
                      <span className="text-2xl">{app.icon}</span>
                      <h3 className="text-lg font-semibold">{app.name}</h3>
                    </div>
                    <p className="text-gray-600 mb-4">{app.description}</p>
                    <button 
                      onClick={() => {
                        setSelectedAppName(app.name);
                        setIntegrationConfigOpen(true);
                      }}
                      className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                    >
                      Configure
                    </button>
                    <IntegrationConfig
                      isOpen={integrationConfigOpen}
                      onClose={() => setIntegrationConfigOpen(false)}
                      position={{ x: window.innerWidth / 2, y: window.innerHeight / 2 }}
                      appName={selectedAppName}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="text-center text-gray-500 py-8">
              No connected integrations yet
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
