
import React from 'react';

interface WorkflowSelectionProps {
  isOpen: boolean;
  onClose: () => void;
  appName: string;
}

const getWorkflowsByApp = (appName: string) => {
  const workflows: Record<string, string[]> = {
    'Datto RMM': ['Run Script', 'Install Software', 'Monitor Device', 'Update Agent'],
    'IT Glue': ['Create Asset', 'Update Documentation', 'Generate Report', 'Link Configuration'],
    'Intune': ['Enroll Device', 'Apply Policy', 'Install Application', 'Reset Password'],
    'JumpCloud': ['Create User', 'Lock Account', 'Reset MFA', 'Update Group'],
    'AirSlate': ['Create Document', 'Send for Signature', 'Archive Document'],
    'Atera': ['Create Ticket', 'Monitor Device', 'Run Script'],
    'Automate.io': ['Create Task', 'Send Email', 'Update Record'],
    'Automox': ['Run Patch', 'Deploy Software', 'Update Policy'],
    'Auvik': ['Monitor Network', 'Run Backup', 'Update Config'],
    'Bitdefender': ['Scan Device', 'Update Policy', 'Quarantine Threat'],
  };
  return workflows[appName] || ['No workflows available'];
};

const WorkflowSelection: React.FC<WorkflowSelectionProps> = ({ isOpen, onClose, appName }) => {
  if (!isOpen) return null;

  const workflows = getWorkflowsByApp(appName);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg w-[400px] max-h-[600px] overflow-y-auto">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">{appName} Workflows</h2>
        </div>
        <div className="p-4">
          <div className="space-y-2">
            {workflows.map((workflow, index) => (
              <button
                key={index}
                className="w-full text-left p-3 rounded border border-gray-200 hover:bg-gray-50 transition-colors"
                onClick={() => {
                  console.log(`Selected workflow: ${workflow}`);
                  onClose();
                }}
              >
                {workflow}
              </button>
            ))}
          </div>
        </div>
        <div className="p-4 border-t border-gray-200 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkflowSelection;
