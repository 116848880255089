
import React, { useState } from 'react'
import { Workflow } from '../types'
import WorkflowBuilder from './WorkflowBuilder'
import WorkflowList from './WorkflowList'

const Home: React.FC = () => {
  const [workflows, setWorkflows] = useState<Workflow[]>([])

  const addWorkflow = (workflow: Workflow) => {
    setWorkflows([...workflows, workflow])
  }

  return (
    <div className="flex flex-col space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white p-6 rounded-lg shadow-md max-h-[400px] overflow-y-auto">
          <WorkflowBuilder addWorkflow={addWorkflow} />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md max-h-[400px] overflow-y-auto">
          <WorkflowList workflows={workflows} />
        </div>
      </div>
      
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Automation Statistics</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="text-4xl font-bold text-blue-600 mb-2">3,139</div>
            <div className="text-gray-600">Total Automations Run</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-green-600 mb-2">98%</div>
            <div className="text-gray-600">Success Rate</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-purple-600 mb-2">355</div>
            <div className="text-gray-600">Hours Saved</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
