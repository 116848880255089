
import React from 'react'
import { Handle, Position } from 'reactflow'

const CustomNode: React.FC<any> = ({ data }) => {
  return (
    <div className="group relative">
      <div
        className="px-2 py-1 rounded-lg bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-white/10 backdrop-blur-sm cursor-pointer"
        onDoubleClick={(e) => {
          e.stopPropagation();
          if (data.handleDoubleClick) {
            data.handleDoubleClick();
          }
        }}
      >
        <Handle 
          type="target" 
          position={Position.Top} 
          className="w-2 h-2 !bg-[#2a8af6] border border-white -top-1"
          isConnectable={true}
        />
        <div className="flex flex-col items-center p-1">
          <span className="text-base">{data.icon}</span>
          <span className="text-[10px] font-medium text-white/90">{data.label}</span>
        </div>
        <Handle 
          type="source" 
          position={Position.Bottom}
          className="w-2 h-2 !bg-[#2a8af6] border border-white -bottom-1"
          isConnectable={true}
        />
      </div>
    </div>
  )
}

export default CustomNode
