import React, { useState, useCallback, useRef } from 'react'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Connection,
  Edge,
  Node,
} from 'reactflow'
import 'reactflow/dist/style.css'
import { AppTile } from '../types'
import { Plus, Trash2, ChevronLeft, ChevronRight } from 'lucide-react'
import DraggableAppTile from './DraggableAppTile'
import CustomNode from './CustomNode'
import IntegrationConfig from './IntegrationConfig'
import WorkflowSelection from './WorkflowSelection'

const appTiles: AppTile[] = [
  { id: 'airslate', name: 'AirSlate', icon: '📝' },
  { id: 'atera', name: 'Atera', icon: '🛠️' },
  { id: 'automate', name: 'Automate.io', icon: '⚙️' },
  { id: 'automox', name: 'Automox', icon: '🔧' },
  { id: 'auvik', name: 'Auvik', icon: '📡' },
  { id: 'bitdefender', name: 'Bitdefender', icon: '🛡️' },
  { id: 'bloomfire', name: 'Bloomfire', icon: '🔥' },
  { id: 'bms', name: 'BMS', icon: '💻' },
  { id: 'box', name: 'Box', icon: '📦' },
  { id: 'chef', name: 'Chef', icon: '👨‍🍳' },
  { id: 'coda', name: 'Coda', icon: '📓' },
  { id: 'confluence', name: 'Confluence', icon: '🌐' },
  { id: 'cwmanage', name: 'CW Manage', icon: '🎛️' },
  { id: 'crowdstrike', name: 'CrowdStrike', icon: '🦅' },
  { id: 'autotask', name: 'Autotask', icon: '🤖' },
  { id: 'dattormm', name: 'Datto RMM', icon: '🔍' },
  { id: 'document360', name: 'Document360', icon: '📚' },
  { id: 'dropbox', name: 'DropBox', icon: '📥' },
  { id: 'egnyte', name: 'Egnyte', icon: '🗄️' },
  { id: 'freshdesk', name: 'Freshdesk', icon: '🎯' },
  { id: 'freshservice', name: 'FreshService', icon: '🔨' },
  { id: 'graphus', name: 'Graphus', icon: '📊' },
  { id: 'guru', name: 'Guru', icon: '🧠' },
  { id: 'halopsa', name: 'HaloPSA', icon: '🌟' },
  { id: 'helpscout', name: 'Help Scout', icon: '💡' },
  { id: 'itglue', name: 'IT Glue', icon: '📘' },
  { id: 'intune', name: 'Intune', icon: '📱' },
  { id: 'jira', name: 'Jira', icon: '🎫' },
  { id: 'jumpcloud', name: 'JumpCloud', icon: '☁️' },
  { id: 'kaseya', name: 'Kaseya VSA', icon: '🎮' },
  { id: 'logicmonitor', name: 'LogicMonitor', icon: '📈' },
  { id: 'manageengine', name: 'ManageEngine', icon: '⚡' },
  { id: 'powerapps', name: 'Power Apps', icon: '⚡' },
  { id: 'ncentral', name: 'N-central', icon: '🌐' },
  { id: 'ninjarmmm', name: 'NinjaRMM', icon: '🥷' },
  { id: 'notion', name: 'Notion', icon: '📔' },
  { id: 'okta', name: 'Okta', icon: '🔑' },
  { id: 'onelogin', name: 'OneLogin', icon: '🔐' },
  { id: 'pdq', name: 'PDQ Deploy', icon: '🚀' },
  { id: 'passportal', name: 'Passportal', icon: '🔒' },
  { id: 'puppet', name: 'Puppet', icon: '🎭' },
  { id: 'servicenow', name: 'ServiceNow', icon: '⚡' },
  { id: 'sharepoint', name: 'SharePoint', icon: '📁' },
  { id: 'solarwinds', name: 'SolarWinds', icon: '☀️' },
  { id: 'syncro', name: 'Syncro', icon: '🔄' },
  { id: 'veeam', name: 'Veeam', icon: '💾' },
  { id: 'workato', name: 'Workato', icon: '🔄' },
  { id: 'zapier', name: 'Zapier', icon: '⚡' },
  { id: 'zendesk', name: 'Zendesk', icon: '🎧' }
]

// Define nodeTypes outside component to avoid recreation
const nodeTypes = {
  custom: CustomNode,
}

const Designer: React.FC = () => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null)
  const [nodes, setNodes, onNodesChange] = useNodesState(
    JSON.parse(sessionStorage.getItem('flow-nodes') || '[]')
  )
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    JSON.parse(sessionStorage.getItem('flow-edges') || '[]')
  )

  React.useEffect(() => {
    sessionStorage.setItem('flow-nodes', JSON.stringify(nodes))
    sessionStorage.setItem('flow-edges', JSON.stringify(edges))
  }, [nodes, edges])
  
  // Validate connections
  const onConnectStart = useCallback((_, { nodeId }) => {
    console.log('Connection started:', nodeId);
  }, []);

  const onConnectEnd = useCallback((event) => {
    console.log('Connection ended:', event);
  }, []);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [integrationConfigOpen, setIntegrationConfigOpen] = useState(false)
  const [integrationConfigPosition, setIntegrationConfigPosition] = useState({ x: 0, y: 0 })
  const [selectedAppName, setSelectedAppName] = useState('')
  const [selectedNode, setSelectedNode] = useState<Node | null>(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [showAutomation, setShowAutomation] = useState(false)
  const [workflowSelectionOpen, setWorkflowSelectionOpen] = useState(false)
  const [selectedAppForWorkflow, setSelectedAppForWorkflow] = useState('')

  const onConnect = useCallback(
    (params: Edge | Connection) => {
      const newEdge = {
        ...params,
        type: 'default',
        animated: true,
        style: { stroke: '#2a8af6', strokeWidth: 2 }
      }
      setEdges((eds) => addEdge(newEdge, eds))
    },
    []
  )

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect()
      const type = event.dataTransfer.getData('application/reactflow')

      if (typeof type === 'undefined' || !type || !reactFlowInstance) {
        return
      }

      const position = reactFlowInstance.project({
        x: event.clientX - (reactFlowBounds?.left ?? 0),
        y: event.clientY - (reactFlowBounds?.top ?? 0),
      })

      const app = appTiles.find((a) => a.id === type)
      if (!app) return

      const newNode: Node = {
        id: `${type}-${Date.now()}`,
        type: 'custom',
        position,
        data: { 
          label: app.name, 
          icon: app.icon,
          handleDoubleClick: () => {
            setSelectedAppForWorkflow(app.name);
            setWorkflowSelectionOpen(true);
          }
        },
      }

      setNodes((nds) => nds.concat(newNode))
    },
    [reactFlowInstance]
  )

  const onNodeClick = useCallback((event: React.MouseEvent, node: Node) => {
    setSelectedNode(node)
  }, [])

  const onPaneClick = useCallback(() => {
    setSelectedNode(null)
  }, [])

  const deleteSelectedNode = useCallback(() => {
    if (selectedNode) {
      setNodes((nds) => nds.filter((node) => node.id !== selectedNode.id))
      setEdges((eds) => eds.filter((edge) => edge.source !== selectedNode.id && edge.target !== selectedNode.id))
      setSelectedNode(null)
    }
  }, [selectedNode, setNodes, setEdges])

  const filteredApps = appTiles.filter((app) =>
    app.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className="h-[calc(100vh-64px)] relative">
      <div className={`absolute left-0 top-0 h-full z-10 transition-transform duration-300 ${isDrawerOpen ? 'translate-x-0' : '-translate-x-[350px]'}`}>
        <div className="w-[350px] h-full bg-white shadow-lg border-r border-gray-200 overflow-y-auto">
        <div className="flex border-b border-gray-200">
          <button 
            className={`flex-1 py-3 px-4 text-center ${!showAutomation ? 'bg-blue-50 border-b-2 border-blue-500 text-blue-600' : 'text-gray-600 hover:bg-gray-50'}`}
            onClick={() => setShowAutomation(false)}
          >
            Apps
          </button>
          <button 
            className={`flex-1 py-3 px-4 text-center ${showAutomation ? 'bg-blue-50 border-b-2 border-blue-500 text-blue-600' : 'text-gray-600 hover:bg-gray-50'}`}
            onClick={() => setShowAutomation(true)}
          >
            Automation
          </button>
        </div>
        <div className="p-4">
          {!showAutomation ? (
            <>
              <input
                type="text"
                placeholder="Search apps..."
                className="w-full p-2 border border-gray-300 rounded mb-4"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="grid grid-cols-2 gap-3">
                {filteredApps.map((app) => (
                  <DraggableAppTile key={app.id} app={app} />
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center mb-4">
                <span className="text-2xl mr-2">🤖</span>
                <input
                  type="text"
                  placeholder="What do you want to automate?"
                  className="flex-1 p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="space-y-4">
                <div className="bg-blue-50 p-4 rounded-lg">
                  <p className="font-semibold text-lg mb-2">Bot Response:</p>
                  <p className="text-gray-700 mb-4">I'll help you automate the application installation process. Here's what we'll do:</p>
                  
                  <div className="space-y-3">
                    <div className="bg-white p-3 rounded border border-blue-200">
                      <p className="font-bold text-blue-800">1. Initial Setup</p>
                      <ul className="list-disc ml-4 text-gray-600">
                        <li>Validate device compatibility</li>
                        <li>Check system requirements</li>
                        <li>Prepare installation environment</li>
                      </ul>
                    </div>
                    
                    <div className="bg-white p-3 rounded border border-blue-200">
                      <p className="font-bold text-blue-800">2. Datto RMM Integration</p>
                      <ul className="list-disc ml-4 text-gray-600">
                        <li>Establish secure connection</li>
                        <li>Transmit installation parameters</li>
                        <li>Generate unique deployment ID</li>
                      </ul>
                    </div>
                    
                    <div className="bg-white p-3 rounded border border-blue-200">
                      <p className="font-bold text-blue-800">3. Deployment Monitoring</p>
                      <ul className="list-disc ml-4 text-gray-600">
                        <li>Track installation progress</li>
                        <li>Monitor system resources</li>
                        <li>Log deployment steps</li>
                      </ul>
                    </div>
                    
                    <div className="bg-white p-3 rounded border border-blue-200">
                      <p className="font-bold text-blue-800">4. Verification & Notification</p>
                      <ul className="list-disc ml-4 text-gray-600">
                        <li>Verify installation success</li>
                        <li>Run post-installation checks</li>
                        <li>Send desktop notification</li>
                      </ul>
                    </div>
                  </div>
                  
                  <button 
                    className="mt-4 w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                    onClick={() => console.log('Building automation...')}
                  >
                    Build Automation
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        </div>
        <button 
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-full bg-white p-2 rounded-r shadow-md border border-l-0 border-gray-200"
        >
          {isDrawerOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
        </button>
      </div>
      
      <ReactFlowProvider>
        <div className="w-full h-full" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeClick={onNodeClick}
            onPaneClick={onPaneClick}
            nodeTypes={nodeTypes}
            snapToGrid={true}
            snapGrid={[15, 15]}
            defaultzoom={0.7}
            minZoom={0.2}
            maxZoom={1.5}
            fitView
            fitViewOptions={{ padding: 0.2 }}
          >
            <Controls />
          </ReactFlow>
          {nodes.length === 0 && (
            <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
              <div className="text-center text-gray-500 bg-white p-8 rounded-lg shadow">
                <Plus className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                <p className="text-lg font-semibold">Start building your workflow</p>
                <p className="mt-2">Drag and drop apps from the left to add steps to your workflow</p>
              </div>
            </div>
          )}
          {selectedNode && (
            <button
              className="absolute bottom-20 right-4 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors duration-200"
              onClick={deleteSelectedNode}
              title="Delete selected node"
            >
              <Trash2 size={20} />
            </button>
          )}
        </div>
      </ReactFlowProvider>
      <IntegrationConfig
        isOpen={integrationConfigOpen}
        onClose={() => setIntegrationConfigOpen(false)}
        position={integrationConfigPosition}
        appName={selectedAppName}
      />
      <WorkflowSelection
        isOpen={workflowSelectionOpen}
        onClose={() => setWorkflowSelectionOpen(false)}
        appName={selectedAppForWorkflow}
      />
    </div>
  )
}

export default Designer