
import React from 'react';

const Templates = () => {
  const templates = [
    // Already loaded templates
    { id: 'user-onboarding', name: 'User Onboarding', loaded: true, category: 'HR', description: 'Automate new employee onboarding process' },
    { id: 'software-deployment', name: 'Software Deployment', loaded: true, category: 'IT', description: 'Automated software installation and configuration' },
    { id: 'access-request', name: 'Access Request Approval', loaded: true, category: 'Security', description: 'Streamlined access management workflow' },
    { id: 'incident-response', name: 'Incident Response', loaded: true, category: 'Operations', description: 'Standardized incident handling process' },
    
    // Available templates
    { id: 'employee-offboarding', name: 'Employee Offboarding', loaded: false, category: 'HR', description: 'Automate employee departure process' },
    { id: 'password-reset', name: 'Password Reset', loaded: false, category: 'IT', description: 'Self-service password reset workflow' },
    { id: 'server-provisioning', name: 'Server Provisioning', loaded: false, category: 'Infrastructure', description: 'Automated server setup and configuration' },
    { id: 'backup-verification', name: 'Backup Verification', loaded: false, category: 'Operations', description: 'Automated backup testing workflow' },
    { id: 'security-audit', name: 'Security Audit', loaded: false, category: 'Security', description: 'Compliance and security check automation' },
    { id: 'device-enrollment', name: 'Device Enrollment', loaded: false, category: 'IT', description: 'New device setup automation' },
    { id: 'license-management', name: 'License Management', loaded: false, category: 'Operations', description: 'Software license tracking workflow' },
    { id: 'compliance-check', name: 'Compliance Check', loaded: false, category: 'Security', description: 'Regulatory compliance verification' },
  ];

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Automation Templates</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {templates.map((template) => (
          <div key={template.id} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-xl font-semibold">{template.name}</h3>
              <span className="px-3 py-1 text-sm rounded-full bg-gray-100 text-gray-600">
                {template.category}
              </span>
            </div>
            <p className="text-gray-600 mb-4">{template.description}</p>
            <div className="flex justify-between items-center">
              {template.loaded ? (
                <span className="text-green-600 font-medium flex items-center">
                  <svg className="w-5 h-5 mr-1" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
                  </svg>
                  Loaded
                </span>
              ) : (
                <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
                  Load Template
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      
      {/* Pagination */}
      <div className="flex justify-center mt-8">
        <nav className="flex items-center space-x-2">
          <button className="px-3 py-2 rounded-md bg-gray-100 text-gray-700 hover:bg-gray-200">Previous</button>
          {[1, 2, 3, '...', 98, 99, 100].map((page, index) => (
            <button
              key={index}
              className={`px-3 py-2 rounded-md ${
                page === 1 ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {page}
            </button>
          ))}
          <button className="px-3 py-2 rounded-md bg-gray-100 text-gray-700 hover:bg-gray-200">Next</button>
        </nav>
      </div>
    </div>
  );
};

export default Templates;
