
import React, { useState } from 'react';
import { X } from 'lucide-react';

interface IntegrationConfigProps {
  isOpen: boolean;
  onClose: () => void;
  position: { x: number; y: number };
  appName: string;
}

const IntegrationConfig: React.FC<IntegrationConfigProps> = ({ isOpen, onClose, position, appName }) => {
  const [activeTab, setActiveTab] = useState<'api' | 'oauth' | 'credentials'>('api');

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={onClose} />
      <div
        className="fixed bg-white rounded-lg shadow-lg w-[500px] z-50"
        style={{
          top: `${position.y}px`,
          left: `${position.x}px`,
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="p-4 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">{appName} Integration Setup</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X size={20} />
            </button>
          </div>
        </div>

        <div className="flex border-b border-gray-200">
          <button
            className={`flex-1 py-2 px-4 ${activeTab === 'api' ? 'border-b-2 border-blue-500 text-blue-600' : ''}`}
            onClick={() => setActiveTab('api')}
          >
            API Configuration
          </button>
          <button
            className={`flex-1 py-2 px-4 ${activeTab === 'oauth' ? 'border-b-2 border-blue-500 text-blue-600' : ''}`}
            onClick={() => setActiveTab('oauth')}
          >
            OAuth Setup
          </button>
          <button
            className={`flex-1 py-2 px-4 ${activeTab === 'credentials' ? 'border-b-2 border-blue-500 text-blue-600' : ''}`}
            onClick={() => setActiveTab('credentials')}
          >
            Credentials
          </button>
        </div>

        <div className="p-6">
          {activeTab === 'api' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">API Key</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Enter API Key"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">API URL</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="https://api.example.com/v1"
                />
              </div>
            </div>
          )}

          {activeTab === 'oauth' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Client ID</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Enter Client ID"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Client Secret</label>
                <input
                  type="password"
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Enter Client Secret"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Redirect URI</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="https://your-app.com/oauth/callback"
                />
              </div>
            </div>
          )}

          {activeTab === 'credentials' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Username</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Enter Username"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
                <input
                  type="password"
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Enter Password"
                />
              </div>
            </div>
          )}

          <div className="mt-6">
            <button className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors">
              Save Configuration
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntegrationConfig;
