import React from 'react'
import { Workflow } from '../types'
import { Play, Edit, Trash2 } from 'lucide-react'

const WorkflowList: React.FC<{ workflows: Workflow[] }> = ({ workflows = [] }) => {
  const fakeWorkflows: Workflow[] = [
    { id: 1, name: "Onboarding - INFOSTRUCTION", steps: [] },
    { id: 2, name: "Offboarding - INFOSTRUCTION", steps: [] },
    { id: 3, name: "Create new user in Office365", steps: [] },
    { id: 4, name: "Expense Report Approval", steps: [] },
  ]

  const displayWorkflows = [...fakeWorkflows, ...workflows]

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Your Workflows</h2>
      <ul className="space-y-4">
        {displayWorkflows.map((workflow) => (
          <li key={workflow.id} className="border border-gray-200 rounded-md p-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium">
                <a href="#" className="text-blue-600 hover:underline">{workflow.name}</a>
              </h3>
              <div className="space-x-2">
                <button className="text-green-600 hover:text-green-800" title="Run Workflow">
                  <Play className="w-5 h-5" />
                </button>
                <button className="text-blue-600 hover:text-blue-800" title="Edit Workflow">
                  <Edit className="w-5 h-5" />
                </button>
                <button className="text-red-600 hover:text-red-800" title="Delete Workflow">
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default WorkflowList