import React from 'react'
import { AppTile } from '../types'

interface DraggableAppTileProps {
  app: AppTile
}

const DraggableAppTile: React.FC<DraggableAppTileProps> = ({ app }) => {
  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
  }

  return (
    <div
      onDragStart={(event) => onDragStart(event, app.id)}
      draggable
      className="bg-gradient-to-br from-white to-gray-50 p-3 rounded-lg shadow-md text-center cursor-move hover:shadow-lg transition-all duration-200 border border-gray-100 hover:border-blue-100 w-full min-h-[85px] flex flex-col items-center justify-center"
    >
      <div className="text-2xl mb-2">{app.icon}</div>
      <div className="font-medium text-sm leading-tight w-full break-words">{app.name}</div>
    </div>
  )
}

export default DraggableAppTile